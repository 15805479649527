import React, { useState, useCallback, useMemo } from 'react';
import { times } from 'lodash';

import Map, {
  Source,
  Layer,
  Popup,
  AttributionControl,
  MapboxGeoJSONFeature,
  MapRef,
  GeoJSONSource
} from "react-map-gl";
import { MapboxEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import Typography from '@mui/material/Typography';

import { SimpleOverlay } from '@/components/molecules';
import { SimpleFeatures, SimpleFact, SimplePointFeature, SimplePoint, SimpleScoreCard } from '@/interfaces';
import { baseLayer, hoverLayer, selectLayer, clusterLayer, clusterCountLayer, clusterPointLayer, pointLayer } from './SimpleLayers';

const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';

type SimpleMapProps = {
  features: SimpleFeatures;
  points: Array<SimplePointFeature>;
}

type hoverInfo = {
  name: string;
  latitude: number;
  longitude: number;
}

export const SimpleMap: React.FC<SimpleMapProps> = (
  {
    features,
    points,
  }
) => {

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number>(0);
  const [hoveredInfo, setHoveredInfo] = useState<hoverInfo | undefined>(undefined);
  const [cursor, setCursor] = useState<string>('auto');
  const [point, setPoint] = useState<SimplePoint | undefined>(undefined);

  const flyTo = (map: MapRef, longitude: number, latitude: number) => {
    map && map.flyTo({center: [longitude + 0.6, latitude], zoom: 8})
  };

  const onClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (feature) {
      if (feature.layer.id === 'base') {
        setSelected(feature);
        flyTo(event.target, feature.properties['LONG'], feature.properties['LAT']);
      } else if (['points', 'points-cluster'].includes(feature.layer.id)) {
        setPoint({...feature.properties});
      }
    } else {
      clearSelected();
      setPoint(undefined);
    }
  }, []);

  const onHover = useCallback(event => {
    const feature = event.features && event.features[0];
    const zoom = event.target.getZoom();
    if (feature && feature.layer.id === 'base' && zoom >= 6) {
      setHovered(feature.id);
      setHoveredInfo({ name: feature.properties['LAD22NM'], latitude: feature.properties['LAT'], longitude: feature.properties['LONG'] });
    } else {
      setHovered(0);
      setHoveredInfo(undefined);
    }

  }, []);

  const clearSelected = () => {
    setSelected(undefined);
    setPoint(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const hoverFilter = useMemo(() => ['==', ['id'], hovered], [hovered]);
  const selectFilter = useMemo(() => ['==', ['id'], selected ? selected.id : 0], [selected]);

  return (
    <Map
      initialViewState={{
        longitude: -4,
        latitude: 53.14,
        zoom: 5.77
      }}
      minZoom={4}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      cursor={cursor}
      // onLoad={onLoad}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onMouseMove={onHover}
      interactiveLayerIds={['base', 'point']}
      // interactiveLayerIds={['unclustered-point']}
      attributionControl={false}
    >
      <AttributionControl
        customAttribution='Contains public sector information licensed under the Open Government Licence v3.0.'
      />
      {/*<NavigationControl*/}
      {/*  position='bottom-right'*/}
      {/*  showCompass={false}*/}
      {/*  visualizePitch={false}*/}
      {/*  style={{ marginBottom: '16px', marginRight: '16px' }}*/}
      {/*/>*/}
      <Source id='lad-source' type='vector' url='mapbox://mpilarczykstarcount.LAD_TCE_LDN'>
        <Layer beforeId="waterway-label" {...baseLayer} />
        <Layer beforeId="waterway-label" {...hoverLayer} filter={hoverFilter}/>
        <Layer beforeId="waterway-label" {...selectLayer} filter={selectFilter}/>
      </Source>
      <Source
        id='points-cluster'
        type='geojson'
        data='/data/points/empty/points_empty.geojson'
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={60}
        clusterMinPoints={5}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...clusterPointLayer} />
      </Source>
      <Source
        id='points'
        type='geojson'
        data='/data/points/empty/points_empty.geojson'
      >
        <Layer {...pointLayer} />
      </Source>
      <SimpleOverlay features={features} pointFeatures={points} selected={selected} clear={clearSelected}/>
      {point &&
        <Popup
          latitude={point.latitude}
          longitude={point.longitude}
          closeButton={false}
          onClose={() => setPoint(undefined)}
        >
          <Typography>{point.name}</Typography>
        </Popup>
      }
      {hoveredInfo &&
        <Popup
          latitude={hoveredInfo.latitude}
          longitude={hoveredInfo.longitude}
          closeButton={false}
          onClose={() => setPoint(undefined)}
        >
          <Typography>{hoveredInfo.name}</Typography>
        </Popup>
      }
    </Map>
  );
}
