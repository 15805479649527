import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme, lighten } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Location, ScoreCardCategory, ScoreCardGroup, ScoreCardResponse } from "@/interfaces";
import { getLads, getScorecard } from "@/services/ApiService";
import { RadarChart, LocationSelectBar, UnderlineHeader, UnderlineText, ScorecardScore } from "@/components/atoms";
import { ScorecardCard, ScorecardGroupCard } from "@/components/molecules";


type ScorecardOverlayProps = {
}

export const ScorecardOverlay: React.FC<ScorecardOverlayProps> = () => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [firstLocation, setFirstLocation] = useState<Location | null>(null);
  const [secondLocation, setSecondLocation] = useState<Location | null>(null);
  const [firstRaw, setFirstRaw] = useState<ScoreCardResponse | undefined>();
  const [secondRaw, setSecondRaw] = useState<ScoreCardResponse | undefined>();
  const [firstData, setFirstData] = useState<Array<ScoreCardGroup>>([]);
  const [secondData, setSecondData] = useState<Array<ScoreCardGroup>>([]);
  const [includeLondon, setIncludeLondon] = useState<boolean>(true);

  useEffect(() => {
    if (firstRaw) {
      includeLondon ? setFirstData(firstRaw.scores) : setFirstData(firstRaw.scores_excl);
    }

    if (secondRaw) {
      includeLondon ? setSecondData(secondRaw.scores) : setSecondData(secondRaw.scores_excl);
    }

  }, [firstRaw, secondRaw, includeLondon]);


  const getGroupNameScores = (scores: Array<ScoreCardGroup>, name: string): Array<ScoreCardCategory> => {
    if (scores.length > 0) {
      return scores.filter(x => x.name === name)[0].scores || [];
    } else {
      return []
    }
  }

  const getGroupScore = (scores: Array<ScoreCardGroup>, name: string): ScoreCardGroup | undefined => {
    if (scores.length > 0) {
      return scores.filter(x => x.name === name)[0] || undefined
    } else {
      return undefined
    }
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLads(accessToken);
      setLocations(response);
    }
    fetchLocations().then();
  }, []);

  useEffect(() => {
    const fetchData = async (location: Location) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getScorecard(location.geo_id, accessToken);
      if (response) {
        setFirstRaw(response);
        setFirstData(response.scores);
      }
    };
    firstLocation && fetchData(firstLocation).then(() => setLoading(false));
    setSecondLocation(null);
    setSecondRaw(undefined);
    setSecondData([]);

  }, [firstLocation]);

  useEffect(() => {
    const fetchData = async (location: Location) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getScorecard(location.geo_id, accessToken);
      if (response) {
        setSecondRaw(response);
        setSecondData(response.scores);
      }
    };
    secondLocation && fetchData(secondLocation).then(() => setLoading(false));
  }, [secondLocation]);

  const onSelectFirst = (location: Location) => {
    setSecondLocation(null);
    setSecondRaw(undefined);
    setSecondData([]);
    setFirstLocation(location);
  }

  const onSelectSecond = (location: Location) => {
    setSecondLocation(location);
  }

  const handleLondonToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeLondon(event.target.checked);
  };

  return (
    <Box width="100%" py={4} px="5%" sx={{ backgroundColor: theme.palette.background.paper, overflow: 'auto'}}>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h5">Location Scorecard</Typography>
        </Grid>
        <Grid item xs={12} mb={4}>
          <>
            <Stack direction="row" spacing={6}>
              <Box>
                {/*<Typography variant="body2" gutterBottom>Select a location to see its scores</Typography>*/}
                <LocationSelectBar options={locations} selectedLocation={firstLocation} onSelect={onSelectFirst} color="info"/>
              </Box>
              {firstLocation && firstData && (
                <Box>
                  {/*<Typography variant="body2" gutterBottom>(Optional) Select a location to compare against</Typography>*/}
                  <LocationSelectBar options={locations} selectedLocation={secondLocation} onSelect={onSelectSecond} color="info"/>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  py: 1,
                  pl: 2,
                  backgroundColor: theme.palette.info.main,
                  borderRadius: '4px',
                  zIndex: 1
                }}
              >
                <FormGroup>
                  <FormControlLabel control={<Switch checked={includeLondon} onChange={handleLondonToggle} size="small" color="default"/>} label="Include London" />
                </FormGroup>
              </Box>
              {/*{loading && <CircularProgress />}*/}
            </Stack>
          </>
        </Grid>
        {firstLocation && firstData.length > 0 && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} mb={1}>
              <Box display="flex" flexDirection="row">
                <UnderlineHeader text={firstLocation.geo_name} color={theme.palette.primary.main} />
                {secondLocation &&
                  <>
                    <Typography variant="h5" sx={{ mx: 1 }}>vs</Typography>
                    <UnderlineHeader text={secondLocation.geo_name} color={theme.palette.secondary.main} />
                  </>
                }
              </Box>
            </Grid>
            {['Group Strategic Priorities', 'Strategic Sectors', 'Hard', 'Soft'].map((group) => (
              <Grid item key={group}>
                <ScorecardGroupCard
                  group={group}
                  locations={[firstLocation, secondLocation]}
                  firstData={getGroupNameScores(firstData, group)}
                  secondData={secondRaw ? getGroupNameScores(secondData, group) : undefined}
                  groupScores={[getGroupScore(firstData, group), secondRaw && getGroupScore(secondData, group)]}
                />
              </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
